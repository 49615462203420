import { memo, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, navigate } from 'gatsby';
import NavBar from '../NavBar';
import logoImg from '../../images/logo.png';
import logoWhite from '../../images/logo-white.png';

import { SECTIONS_PATHS } from '../../utils/data';
import { messages } from '../messages';
import { GlobalContext } from '../../context';
import { resetSteps } from '../../context/actions';
import HamburgerButton from '../HamburgerButton/HamburgerButton';
// import ToggleSwitch from '../ToggleSwitch';
import useThemeSwitch from '../../hooks/useThemeSwitch';

const StyledHeader = styled.header<{
  scroll?: boolean;
  fixed?: boolean;
  internal?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 60px;
  height: 108px;
  background: ${props => props.theme.colors.mainBackground};
  position: ${({ fixed }) => (fixed ? 'fixed' : 'static')};
  width: 100%;
  transition: all 0.2s ease, background-color 0.5s ease 0s;
  box-shadow: ${props =>
    props.scroll
      ? 'rgba(0, 0, 0, 0.15) 0px 5px 23px 0px'
      : 'rgba(0, 0, 0, 0) 0px 5px 10px 0px'};
  z-index: 5;

  button.hamburgerBtn,
  div.backDrop {
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 10px 20px;
    height: 70px;
  }
  .header-logo {
    width: 258px;
    max-width: 30%;
    @media only screen and (max-width: 1190px) {
      max-width: 20%;
    }
    @media only screen and (max-width: 640px) {
      max-width: 30%;
    }
  }
`;

const Backdrop = styled.div<{ opened?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  z-index: 4;
  opacity: ${({ opened }) => (opened ? '0.6' : '0')};
  visibility: ${({ opened }) => (opened ? 'visible' : 'hidden')};
  transition: all 0.4s ease;
`;

const ResponsiveNavBar = styled.div<{ opened?: boolean }>`
  @media (max-width: 1024px) {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 240px;
    background-color: ${props => props.theme.colors.mainBackground};
    box-shadow: 0 0 20px 0 ${props => props.theme.colors.shadow};
    transform: ${({ opened, theme }) =>
      // eslint-disable-next-line no-nested-ternary
      opened
        ? 'translateX(0)'
        : theme.rtl
        ? 'translateX(calc(100% + 20px))'
        : 'translateX(calc(-100% - 20px))'};
    transition: all 0.4s ease;
    z-index: 5;
    ${({ theme }) =>
      theme.rtl &&
      `
      right: 0;
      left: auto;
    `}
    nav {
      padding: 30px;
      ul {
        flex-direction: column;
        align-items: flex-start;
        li {
          padding: 12px 0;
          width: 100%;
          a,
          span {
            font-size: 14px;
            width: 100%;
          }
        }
      }
    }
  }
`;

interface HeaderProps {
  activeLink?: string;
  fixed?: boolean;
  internal?: boolean;
}

function Header({
  activeLink = '',
  fixed = false,
  internal = false,
}: HeaderProps) {
  const { dispatch } = useContext(GlobalContext);

  const [scroll, setScroll] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const { t } = useTranslation();

  const { themeMode } = useThemeSwitch();

  const handleScroll = useCallback(() => {
    const scrollOffset = window.scrollY;
    // console.log({ scrollOffset, scroll });
    if (scrollOffset > 20 && !scroll) {
      setScroll(true);
    } else if (scrollOffset === 0 && scroll) {
      setScroll(false);
    }
  }, [setScroll, scroll]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    const handleEsc = (event: { keyCode: number }) => {
      if (event.keyCode === 27) {
        setMenuOpened(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('scroll', handleScroll);
      window.removeEventListener('keydown', handleEsc);
    };
  }, [handleScroll]);

  /* const handleLanguageChange = () => {
    i18n.changeLanguage(i18n.language === 'fr' ? 'ar' : 'fr');
  }; */
  return (
    <StyledHeader scroll={scroll} fixed={fixed} internal={internal}>
      <div className="header-logo">
        <Link to="/">
          <img src={themeMode === 'light' ? logoImg : logoWhite} alt="logo" />
        </Link>
      </div>
      <HamburgerButton
        opened={menuOpened}
        onClick={() => setMenuOpened(v => !v)}
      />
      <Backdrop
        className="backDrop"
        opened={menuOpened}
        onClick={() => setMenuOpened(false)}
      />

      <ResponsiveNavBar opened={menuOpened}>
        <NavBar>
          <NavBar.Link>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
            <span
              role="button"
              onClick={async e => {
                e.preventDefault();
                dispatch(resetSteps());
                await navigate(`/${SECTIONS_PATHS.PAYMENT}`);
              }}
              className={`${
                activeLink === SECTIONS_PATHS.PAYMENT ? 'active' : ''
              }`}
            >
              {t(messages.navigationBar.paymentLinkTitle())}
            </span>
          </NavBar.Link>
          <NavBar.Link
            to={`/#${SECTIONS_PATHS.HOW_IT_WORKS}`}
            className={`${
              activeLink === SECTIONS_PATHS.HOW_IT_WORKS ? 'active' : ''
            }`}
          >
            {t(messages.navigationBar.howItWorksLinkTitle())}
          </NavBar.Link>
          {/*          <NavBar.Link
            to={`/${internal ? '' : '#'}${SECTIONS_PATHS.RAFFLE}`}
            className={`${
              activeLink === SECTIONS_PATHS.RAFFLE ? 'active' : ''
            }`}
          >
            {t(messages.navigationBar.raffleLinkTitle())}
          </NavBar.Link> */}
          <NavBar.Link>
            <a
              href="https://vignette.ma/attestTsava/consulterTSAVA.do?method=init"
              target="_blank"
              rel="noreferrer"
            >
              {t(messages.navigationBar.editQuittanceLinkTitle())}
            </a>
          </NavBar.Link>
          <NavBar.Link
            to={`/${internal ? '' : '#'}${SECTIONS_PATHS.FAQ}`}
            className={`${activeLink === SECTIONS_PATHS.FAQ ? 'active' : ''}`}
          >
            {t(messages.navigationBar.faqLinkTitle())}
          </NavBar.Link>
          <NavBar.Link
            to={`/#${SECTIONS_PATHS.CONTACT}`}
            className={`${
              activeLink === SECTIONS_PATHS.CONTACT ? 'active' : ''
            }`}
          >
            {t(messages.navigationBar.contactLinkTitle())}
          </NavBar.Link>
          {/* <ToggleSwitch checked={themeMode === 'dark'} onChange={toggleTheme} /> */}
          {/* <button onClick={handleLanguageChange}>change lang</button> */}
        </NavBar>
      </ResponsiveNavBar>
    </StyledHeader>
  );
}

export default memo(Header);
