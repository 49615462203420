import { _t } from '../utils/messages';
import { translations } from '../locales/translations';

// eslint-disable-next-line import/prefer-default-export
export const messages = {
  navigationBar: {
    paymentLinkTitle: () =>
      _t(translations.navigationBar.paymentLinkTitle, 'payer ma vignette'),
    howItWorksLinkTitle: () =>
      _t(translations.navigationBar.howItWorksLinkTitle, 'comment ça marche'),
    raffleLinkTitle: () =>
      _t(translations.navigationBar.raffleLinkTitle, 'tombola'),
    editQuittanceLinkTitle: () =>
      _t(
        translations.navigationBar.editQuittanceLinkTitle,
        'éditer ma quittance',
      ),
    faqLinkTitle: () => _t(translations.navigationBar.faqLinkTitle, 'faq'),
    contactLinkTitle: () =>
      _t(translations.navigationBar.contactLinkTitle, 'contact'),
    legalNoticeLinkTitle: () =>
      _t(translations.navigationBar.legalNoticeLinkTitle, 'mentions légales'),
    termsAndConditionsLinkTitle: () =>
      _t(
        translations.navigationBar.termsAndConditionsLinkTitle,
        "conditions générales d'utilisation",
      ),
  },
  errors: {
    inputRequired: () => _t('errors.inputRequired', '* Obligatoire'),
    emailNotValid: () =>
      _t('errors.emailNotValid', '* Adresse email invalide!'),
    emailNotIdentical: () =>
      _t(
        'errors.emailNotIdentical',
        '* les deux emails ne sont pas identiques',
      ),
    phoneNumberNotValid: () =>
      _t('errors.phoneNumberNotValid', '* numéro de téléphone invalide!'),
    multiInput: {
      carRegistration: () =>
        _t(
          'errors.multiInput.carRegistration',
          '* Veuillez saisir une valeur Immatriculation valide',
        ),
    },
  },
  custom: {
    loadingText: () => _t('custom.loadingText', 'Opération en cours...'),
    acceptText: () => _t('custom.acceptText', "J'accepte les"),
    acceptExtraText: () => _t('custom.acceptExtraText', 'et les'),
  },
  footer: {
    title: () => _t(translations.footer.title, 'tous les droits réservés'),
    companyName: () => _t('footer.companyName', 'Société Générale Maroc'),
  },
};
