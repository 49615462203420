import styled from 'styled-components';
import { CSSProperties, memo } from 'react';

const StyledSectionHeadingContainer = styled.div<{
  align?: 'center' | 'flex-start';
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => (align === 'center' ? 'center' : 'flex-start')};
  position: relative;
  text-align: ${({ align }) => (align === 'center' ? 'center' : 'left')};
  p {
    font-family: ${props => props.theme.fonts.SourceSansPro};
    font-size: 18px;
    font-weight: ${props => props.theme.fontWeights.SourceSansPro.regular};
    color: ${props => props.theme.colors.secondary};
    @media only screen and (max-width: 1024px) {
      font-size: 14px;
    }
  }
`;
const StyledSectionHeading = styled.h2<{
  align?: 'center' | 'flex-start';
  withShadow?: boolean;
}>`
  font-family: ${props => props.theme.fonts.Montserrat};
  font-size: 40px;
  font-weight: ${props => props.theme.fontWeights.Montserrat.extraBold};
  color: ${props => props.theme.colors.primary};
  text-transform: uppercase;
  line-height: 34px;
  position: relative;
  padding-bottom: 20px;
  display: inline-flex;
  flex-direction: column;
  align-items: ${({ align }) => (align === 'center' ? 'center' : 'flex-start')};
  margin-bottom: 16px;
  text-shadow: ${({ withShadow }) =>
    withShadow ? '4px 4px 14px #000' : 'none'};
  @media only screen and (max-width: 1024px) {
    font-size: 30px;
    line-height: 24px;
    padding-bottom: 14px;
  }
  @media only screen and (max-width: 640px) {
    font-size: 24px;
    padding-bottom: 10px;
  }
  > span {
    font-family: ${props => props.theme.fonts.SourceSansPro};
    font-size: 26px;
    font-weight: ${props => props.theme.fontWeights.SourceSansPro.bold};
    color: ${props => props.theme.colors.secondary};
    @media only screen and (max-width: 1024px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 640px) {
      font-size: 16px;
    }
  }
  &:before {
    content: '';
    height: 6px;
    width: 146px;
    background-color: ${props => props.theme.colors.primary};
    position: absolute;
    bottom: 0;
    left: ${({ align }) => (align === 'center' ? '50%' : '0')};
    right: ${({ align }) => (align === 'center' ? 'auto' : 'auto')};
    ${({ align, theme }) =>
      theme.rtl &&
      `
      left : ${align === 'center' ? '50%' : 'auto'};
      right : ${align === 'center' ? 'auto' : 'auto'};
    `}
    transform: ${({ align }) =>
      align === 'center' ? 'translateX(-50%)' : 'translateX(0)'};

    box-shadow: ${({ withShadow }) =>
      withShadow ? '4px 4px 14px #000' : 'none'};
    @media only screen and (max-width: 1024px) {
      height: 4px;
    }
  }
`;

interface SectionHeadingProps {
  title?: string;
  subTitle?: string;
  description?: string;
  align?: 'center' | 'flex-start';
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  style?: CSSProperties;
  withShadow?: boolean;
}

function SectionHeading({
  as = 'h2',
  title,
  subTitle,
  description,
  align = 'center',
  style,
  withShadow,
}: SectionHeadingProps) {
  return (
    <StyledSectionHeadingContainer align={align} style={style}>
      <StyledSectionHeading as={as} align={align} withShadow={withShadow}>
        {subTitle && <span>{subTitle}</span>}
        {title && <strong>{title}</strong>}
      </StyledSectionHeading>
      {description && <p>{description}</p>}
    </StyledSectionHeadingContainer>
  );
}

export default memo(SectionHeading);
