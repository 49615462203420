import { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { NavBarContext } from './useNavBarContext';
import NavItem from './NavItem';
import useEffectAfterMount from '../../hooks/useEffectAfterMount';

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    li {
      padding: 0 25px;
      @media only screen and (max-width: 1190px) {
        padding: 0 10px;
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      a,
      span {
        cursor: pointer;
        font-family: ${props => props.theme.fonts.SourceSansPro};
        font-weight: 700;
        font-size: 16px;
        color: ${props => props.theme.colors.secondary};
        text-decoration: none;
        text-transform: uppercase;
        transition: all 0.4s ease;
        position: relative;
        overflow: hidden;
        display: inline-block;
        &:after {
          background: #fff;
          content: '';
          height: 50px;
          left: calc(-50% - 70px);
          opacity: 0.2;
          position: absolute;
          top: 50%;
          transform: rotate(35deg) translateY(-50%);
          transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          width: 50px;
          z-index: 0;
        }

        &.active,
        &:hover {
          color: ${props => props.theme.colors.primary};
          &:after {
            left: 130%;
          }
        }
      }
    }
  }
`;

interface NavBarProps {
  children: ReactElement[];
  // eslint-disable-next-line no-unused-vars
  onItemChange?: (title: string) => void;
  defaultActiveItem?: string;
}

const NavBar = ({
  children,
  onItemChange = () => {},
  defaultActiveItem,
}: NavBarProps) => {
  const [activeItem, setActiveItem] = useState<string>(
    defaultActiveItem || children[0].props.title,
  );

  useEffectAfterMount(() => {
    onItemChange(activeItem);
  }, [activeItem, onItemChange]);

  return (
    <NavBarContext.Provider value={{ activeItem, setActiveItem }}>
      <StyledNav>
        <ul>{children}</ul>
      </StyledNav>
    </NavBarContext.Provider>
  );
};

NavBar.Link = NavItem;

export default NavBar;
