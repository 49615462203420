import styled from 'styled-components';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import NavBar from '../NavBar';
import logo from '../../images/logo.png';
import logoWhite from '../../images/logo-white.png';
import supportedPayment from '../../images/payment-methods.png';
import { messages } from '../messages';
import { SECTIONS_PATHS } from '../../utils/data';
import { resetSteps } from '../../context/actions';
import { GlobalContext } from '../../context';

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;

  nav {
    background: ${props => props.theme.colors.mainBackground};
    box-shadow: 0 0 40px 10px ${props => props.theme.colors.shadow};
    padding: 20px;
    > ul {
      justify-content: center;
      min-height: 60px;
      @media only screen and (max-width: 640px) {
        flex-wrap: wrap;
      }
      li {
        font-size: 0.8rem;
        height: 60px;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;

        @media only screen and (max-width: 640px) {
          width: 33%;
          height: 30px;
          padding: 0;
        }
        @media only screen and (max-width: 480px) {
          width: 50%;
        }
        &:before {
          content: '-';
          position: absolute;
          left: ${props => (props.theme.rtl ? '100%' : 0)};
          top: 50%;
          transform: translate(-2px, -50%);
          @media only screen and (max-width: 640px) {
            content: none;
          }
        }
        &:first-child {
          padding: 0 10px;
          @media only screen and (max-width: 640px) {
            padding: 0;
          }
          &:before {
            content: none;
            @media only screen and (max-width: 640px) {
              padding: 0;
            }
          }
        }
        &:last-child {
          padding: 0 10px;
          @media only screen and (max-width: 640px) {
            padding: 0;
          }
        }
        a,
        span {
          cursor: pointer;
          font-size: 14px;
          font-weight: ${props =>
            props.theme.fontWeights.SourceSansPro.regular};

          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
          &.active,
          &:hover {
            color: ${props => props.theme.colors.primary};
          }

          &:after {
            display: none;
          }
        }
      }
    }
  }
`;

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 70px;
  min-height: 100px;
  @media only screen and (max-width: 1024px) {
    padding: 10px 20px;
  }
  @media only screen and (max-width: 640px) {
    flex-wrap: wrap;
    padding: 20px;
    min-height: 120px;
  }
`;

const FooterLogo = styled.div`
  max-width: 224px;
  @media only screen and (max-width: 1024px) {
    max-width: 20%;
  }
  @media only screen and (max-width: 640px) {
    max-width: 30%;
  }
`;

const RightsReserved = styled.p`
  text-transform: uppercase;
  @media only screen and (max-width: 1024px) {
    font-size: 10px;
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
    order: 3;
    text-align: center;
    margin-top: 20px;
  }
`;

const CmiLogo = styled.div`
  background: ${props =>
    props.theme.mode === 'dark' ? 'white' : 'transparent'};
  padding: 5px;
  border-radius: 5px;
`;

interface FooterProps {
  activeLink?: string;
}
function Footer({ activeLink = '' }: FooterProps) {
  const {
    dispatch,
    state: { themeMode },
  } = useContext(GlobalContext);
  const { t } = useTranslation();
  return (
    <StyledFooter>
      <NavBar>
        <NavBar.Link>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
          <span
            role="button"
            onClick={async e => {
              e.preventDefault();
              dispatch(resetSteps());
              await navigate(`/${SECTIONS_PATHS.PAYMENT}`);
            }}
            className={`${
              activeLink === SECTIONS_PATHS.PAYMENT ? 'active' : ''
            }`}
          >
            {t(messages.navigationBar.paymentLinkTitle())}
          </span>
        </NavBar.Link>
        {/*        <NavBar.Link>
           eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
          <span
            role="button"
            onClick={async e => {
              e.preventDefault();
              await navigate(`/${SECTIONS_PATHS.RAFFLE}`);
            }}
            className={`${
              activeLink === SECTIONS_PATHS.RAFFLE ? 'active' : ''
            }`}
          >
            {t(messages.navigationBar.raffleLinkTitle())}
          </span>
        </NavBar.Link> */}
        <NavBar.Link>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
          <span
            role="button"
            onClick={async e => {
              e.preventDefault();
              await navigate(`/${SECTIONS_PATHS.FAQ}`);
            }}
            className={`${activeLink === SECTIONS_PATHS.FAQ ? 'active' : ''}`}
          >
            {t(messages.navigationBar.faqLinkTitle())}
          </span>
        </NavBar.Link>
        <NavBar.Link>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
          <span
            role="button"
            onClick={async e => {
              e.preventDefault();
              await navigate(`/#${SECTIONS_PATHS.CONTACT}`);
            }}
            className={`${
              activeLink === SECTIONS_PATHS.CONTACT ? 'active' : ''
            }`}
          >
            {t(messages.navigationBar.contactLinkTitle())}
          </span>
        </NavBar.Link>
        <NavBar.Link to={`/${SECTIONS_PATHS.LEGAL_NOTICE}`}>
          <span
            className={`${
              activeLink === SECTIONS_PATHS.LEGAL_NOTICE ? 'active' : ''
            }`}
          >
            {t(messages.navigationBar.legalNoticeLinkTitle())}
          </span>
        </NavBar.Link>
        <NavBar.Link to={`/${SECTIONS_PATHS.TERMS}`}>
          <span
            className={`${activeLink === SECTIONS_PATHS.TERMS ? 'active' : ''}`}
          >
            {t(messages.navigationBar.termsAndConditionsLinkTitle())}
          </span>
        </NavBar.Link>
      </NavBar>
      <BottomContainer>
        <FooterLogo>
          <img src={themeMode === 'light' ? logo : logoWhite} alt="logo" />
        </FooterLogo>
        <RightsReserved>
          2021 - {t(messages.footer.title())},{' '}
          {t(messages.footer.companyName())}
        </RightsReserved>
        <CmiLogo>
          <img src={supportedPayment} alt="supported payment methods" />
        </CmiLogo>
      </BottomContainer>
    </StyledFooter>
  );
}

export default memo(Footer);
