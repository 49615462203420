import { FC, memo, ReactNode } from 'react';
import { Link } from 'gatsby';
import useNavBarContext from './useNavBarContext';

export interface NavItemProps {
  to?: string;
  children: ReactNode;
  isActive?: boolean;
  className?: string;
}

const NavItem: FC<NavItemProps> = ({ to = '', className = '', children }) => {
  const { setActiveItem } = useNavBarContext();

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <li onClick={() => setActiveItem(to)}>
      {to ? (
        <Link to={to} className={className}>
          {children}
        </Link>
      ) : (
        children
      )}
    </li>
  );
};

export default memo(NavItem);
